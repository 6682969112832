<template>
    <div id="mine">
        <div class="mine-header-wrapper">
            <div class="mine-user">
                <!-- <img src="../../assets/images/userLogo.jpg" alt="头像"> -->
                <UserLogoComponent width="96" height="96" :user="user" :disabled-hover="true" />
                <div class="info">
                    <div class="user-name">
                        {{ user.nickname || '&nbsp;' }}
                    </div>
                    <div class="other">
                        <span class="follow">
                            <span class="value">{{ follow_count || 0 }}</span>
                            关注
                        </span>
                        <span class="fan">
                            <span class="value">{{ fans_count || 0 }}</span>
                            粉丝
                        </span>
                        <!-- <span class="ip">
                            IP属地：
                            <span>湖北</span>
                        </span> -->
                    </div>
                </div>
                <div v-if="userInfo.id == user.id" class="setting">
                    <el-button @click="handleSetting">编辑资料</el-button>
                </div>
            </div>
        </div>
        <div class="mine-body-wrapper">
            <div class="left">
                <div class="main-tabs">
                    <div :class="['item', active === 0 ? 'active' : '']" @click="handleMainTabChange(0)">帖子</div>
                    <div :class="['item', active === 1 ? 'active' : '']" @click="handleMainTabChange(1)">关注</div>
                    <div :class="['item', active === 2 ? 'active' : '']" @click="handleMainTabChange(2)">粉丝</div>
                </div>
                <div v-if="!active" class="posts">
                    <div v-for="item in posts" :key="item.id" class="post-item">
                        <!-- <img src="../../assets/images/userLogo.jpg" alt=""> -->
                        <UserLogoComponent width="48" height="48" :user="item.user_info" />
                        <div :class="['post-body', item.title ? 'has-title' : '']">
                            <div class="user-name">{{ item.user_info ? item.user_info.nickname || '' : '' }}</div>
                            <div class="time" @click="handleView(item.id)">{{ item.create_time || '' }}</div>
                            <div v-if="item.title" class="title" v-html="item.title" @click="handleView(item.id)"></div>
                            <div class="content" v-html="item.content" @click="handleView(item.id)"></div>
                            <div v-if="item.parent_post_article && item.parent_post_article.id" class="parent-post"
                                @click="handleView(item.parent_post_article.id)">
                                <div class="user">
                                    <!-- <img src="../../assets/images/userLogo.jpg"> -->
                                    <UserLogoComponent
                                        width="20" height="20"
                                        :user="{
                                            avatar: item.parent_post_article.user_info_avatar,
                                            id: item.parent_post_article.user_info_id,
                                        }"
                                    />
                                    <span class="user-name">{{ item.parent_post_article.user_info_name }}</span>
                                </div>
                                <div class="title">{{ item.parent_post_article.title }}</div>
                                <div v-if="item.parent_post_article.content" class="content"
                                    v-html="item.parent_post_article.content"></div>
                                <div class="other">
                                    <span class="time">{{ item.parent_post_article.create_time }}</span>
                                </div>
                            </div>
                            <div class="action">
                                <div class="repeat" @click="handlePostRepeatOpenDialog(item)">
                                    <postRepeat />
                                    <span>
                                        转发
                                        <template v-if="item.repeat_count">
                                            ({{ item.repeat_count }})
                                        </template>
                                    </span>
                                </div>
                                <div class="split"></div>
                                <div class="comment" @click="handleOpenCommentEditor(item)">
                                    <reply />
                                    <span>
                                        评论
                                        <template v-if="item.comment_count">
                                            ({{ item.comment_count }})
                                        </template>
                                    </span>
                                </div>
                                <div class="split"></div>
                                <div class="like">
                                    <like v-if="!item.is_like" />
                                    <likeActive v-else />
                                    <span>
                                        赞
                                        <template v-if="item.like_count">
                                            ({{ item.like_count }})
                                        </template>
                                    </span>
                                </div>
                                <div class="split"></div>
                                <div class="collect" @click="handleCollectChange(item)">
                                    <collect v-if="!item.is_collection" />
                                    <collectActive v-else />
                                    <span>
                                        <!-- 收藏 -->
                                        {{ item.is_collection ? '取消收藏' : '收藏' }}
                                    </span>
                                </div>
                                <template v-if="userInfo.id == user.id">
                                    <div class="split"></div>
                                    <div class="setting">
                                        <setting />
                                        <span>设置</span>
                                    </div>
                                    <div class="split"></div>
                                    <div class="delete" @click="handleDelete(item)">
                                        <deleteIcon />
                                        <span>删除</span>
                                    </div>
                                </template>
                            </div>
                            <CommentEditor v-if="currentPostId == item.id" ref="comment-comment-editor-ref"
                                type="comment" @handlePublish="handleCommentComment"
                                @handleCancel="handleCancelComment" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">

            </div>
        </div>
        <PostRepeatDialog :dialogVisible="repeatDialogVisible" @handleClose="handleCloseRepeatDialog"
            @handleSubmit="handleSubmitPostRepeat" />
    </div>
</template>

<script>
import { reply, like, likeActive, collect, collectActive, deleteIcon, setting, postRepeat } from '../../core/icon';
import CommentEditor from '../../components/CommentEditor';
import PostRepeatDialog from '../../components/PostRepeatDialog/index.vue';
import UserLogoComponent from '../../components/UserLogo/index.vue';
export default {
    name: 'U',
    components: {
        reply,
        like,
        likeActive,
        collect,
        collectActive,
        deleteIcon,
        setting,
        postRepeat,

        CommentEditor,
        PostRepeatDialog,
        UserLogoComponent,
    },
    data() {
        return {
            active: 0,
            posts: [],
            currentPostId: '',
            fans_count: 0,
            follow_count: 0,
            user: {},
            user_follow_ship: {},

            //文章转发弹窗显示
            repeatDialogVisible: false,
            currentRepeatId: '',
        }
    },
    activated() {
        console.log('activated, U', this.$route);

        if (this.$route.params.id) {
            // document.title = this.userInfo.username + ' - ' + '碳问';
            this.getUserInfoById();
            this.getUserPostArticleList();
        }

    },
    computed: {
        token() {
            return this.$store.state.token;
        },
        userInfo() {
            return this.$store.state.userInfo;
        }
    },
    watch: {
        token: function (val) {
            this.getUserInfoById();
            this.getUserPostArticleList();
        }
    },
    methods: {
        getUserInfoById(){
            let user_id = decodeURIComponent(decodeURIComponent(this.$route.params.id));
            // /api/user/getuserinfobyid
            this.api.postFormAPISM(
                {
                    user_id,
                },
                '/user/getuserinfobyid'
            ).then(res => {
                console.log('getUserInfoById', res.data);
                let { fans_count, follow_count, user, user_follow_ship } = res.data.data;
                this.fans_count = fans_count;
                this.follow_count = follow_count;
                this.user = user;
                this.user_follow_ship = user_follow_ship;
                document.title = user.nickname + ' - ' + '碳问';
            }).catch(error => {
                console.log('error', error);
            })
        },
        handleMainTabChange(active) {
            this.active = active;
            if (this.active === 0) {
                this.getUserPostArticleList();
            }
        },
        getUserPostArticleList() {
            // /api/article/getpostarticlelist
            let user_id = decodeURIComponent(decodeURIComponent(this.$route.params.id));
            this.api.postFormAPISM(
                {
                    limit: 20,
                    keywords: '',
                    start_time: '',
                    user_id,
                },
                '/article/getpostarticlelist',
            ).then(res => {
                this.posts = res.data.data.article_info_list
                console.log('res', this.posts);
            }).catch(error => {
                console.log('error', error);
            })
        },

        //对文章转发 打开转发弹窗dialog
        handlePostRepeatOpenDialog(item) {
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                // return this.handleOpenLogin();
                return;
            }
            this.repeatDialogVisible = true;
            this.currentRepeatId = item.id;
        },
        // //对文章转发 提交弹窗dialog
        handleSubmitPostRepeat(content) {
            // /api/article/repeat
            this.api.postFormAPISM(
                {
                    parent_post_article_id: this.currentRepeatId,
                    content,
                    is_draft: false,
                    post_article_id: null,
                },
                '/article/repeat'
            ).then(res => {
                console.log('handleSubmitPostRepeat', res.data, res.data.hasOwnProperty('code'));
                if (res.status === 200 && typeof res.data === 'object' && !(res.data.hasOwnProperty('code'))) {
                    this.$message({
                        type: 'success',
                        message: '转发成功'
                    });
                    this.handleCloseRepeatDialog();
                    this.getUserPostArticleList();
                } else {
                    this.$message({
                        type: 'error',
                        message: res.data ? res.data.message || '转发失败' : '转发失败',
                    });
                }

            }).catch(error => {
                console.log('error', error);
            })
        },
        handleCloseRepeatDialog() {
            this.repeatDialogVisible = false;
        },

        //打开评论 编辑器
        handleOpenCommentEditor(item) {
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                return
                // return this.handleOpenLogin();
            }
            let { id } = item;
            this.currentPostId = id;
        },
        // 进行评论确定回调
        handleCommentComment(text) {
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                return;
                // return this.handleOpenLogin();
            }
            console.log('handleCommentComment', text);
            // /api/article/comment
            this.api.postFormAPISM(
                {
                    post_article_id: this.currentPostId,
                    comment_content: text,
                    parent_comment_id: null,
                },
                '/article/comment'
            ).then(res => {
                // console.log('handleCommentPost', res.data);
                this.handleCancelComment();
                this.getMyPostArticleList();
            }).catch(error => {
                console.log('error', error);
            })
        },
        // 对评论取消评论
        handleCancelComment() {
            let ref = this.$refs['comment-comment-editor-ref'];
            // console.log('ref', ref);
            ref.handleClear && ref.handleClear();
            this.currentPostId = '';
        },

        //收藏 变更
        handleCollectChange(item){
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                return
                // return this.handleOpenLogin();
            }
            let { id, is_collection } = item;
            // /api/article/collection
            this.api.postFormAPISM(
                {
                    post_article_id: id,
                    is_collection: !is_collection,
                },
                '/article/collection'
            ).then(res => {
                console.log('res', res.data);
                this.getUserPostArticleList();
            }).catch(error => {
                console.log('error', error);
            })
        },

        //删除
        async handleDelete({ id }){
            // console.log('handleDelete', this.$confirm);
            let confirmPromise = new Promise((resolve, reject) => {
                this.$confirm("确定删除吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    confirmButtonClass: "butText",
                    type: "info",
                    customClass: 'my-custom-confirm',
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                }).then(() => {
                    console.log('confirm');
                    resolve(true);
                }).catch(error => {
                    resolve(false);
                })
            });
            let confirm = await confirmPromise;
            if (!confirm) return;
            // /api/article/delete
            this.api.postFormAPISM(
                {
                    post_article_id: id,
                },
                '/article/delete'
            ).then(res => {
                this.getUserPostArticleList();
            }).catch(error => {
                console.log('error', error);
            })
        },
        handleView(id) {
            // this.$router.push(
            //     {
            //         path: '/news',
            //         query: {
            //             page: page + 1,
            //         }
            //     }
            // );
            let text = this.$router.resolve({
                path: "/post",
                query: {
                    id,
                },
            });
            // console.log("text", text.href);
            // 打开一个新的页面
            window.open(text.href, "_blank");
        },
        handleSetting() {
            console.log('handleSetting');
            let route = this.$router.resolve({
                path: '/setting'
            });
            window.open(route.href, '_blank');
        }
    }
}
</script>
<style lang="less">
@import './index.less';
</style>